<template>
  <ul class="nav nav-pills">
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/client/'+client.uuid" v-bind:class="$route.path === '/client/'+client.uuid?'active':''">Основна інформація</router-link>
    </li>
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/client/'+client.uuid+'/order'" v-bind:class="$route.path.startsWith('/client/'+client.uuid +'/order')?'active':''">Замовлення</router-link>
    </li>
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/client/'+client.uuid+'/promo-code'" v-bind:class="$route.path.startsWith('/client/'+client.uuid +'/promo-code')?'active':''">Промокоди</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    client: Object
  },
}
</script>

<style scoped>

</style>