<template>
  <head-panel>
    <template v-slot:body>
      Каталог <span v-if="catalog"> {{ catalog.name }}</span>

      <router-link to="/planting">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>

      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="catalog && this.$session.can('owner', 'catalog_manage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити магазин
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper">

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page"
           @click="show('main')">Основне</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="show('meta')" v-if="catalog">Мета</a>
      </li>
    </ul>

    <hr/>

    <form @submit.prevent="submit">

      <div class="tab" v-if="tab==='main'">
        <Alert ref="alert"/>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Назва</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name"
                   :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Шлях</div>
          <div class="col-lg-8">
            <div class="input-group ">
              <span class="input-group-text" id="basic-addon3">/catalog/</span>
              <input type="text" class="form-control" v-model="form.path"
                     :class="{ 'is-invalid' : v$.form.path.$error}">
              <div class="invalid-feedback" v-for="error of v$.form.path.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Переклади</div>
          <div class="col-lg-8">
            <locale-form v-model="form.locales"/>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>

      </div>

      <div class="tab" v-if="tab==='meta'">
        <Alert ref="alert"/>
        <meta-form v-model="form.meta" :with-label="true"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import MetaForm from "../../components/MetaForm";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";
import Alert from "../../components/Alert.vue";
import LocaleForm from "../../components/LocaleForm";


export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, MetaForm, Alert, LocaleForm
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        path: {required},
        locales: {
          required
        }
      }
    };
  },
  data() {
    return {
      catalog: null,
      form: {
        name: null,
        preview: null,
        meta: null,
        path: null,
        locales: null,
      },
      tab: 'main'
    }
  },
  methods: {
    show: function (tab) {
      this.tab = tab;
    },
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/planting/" + id)
          .then((res) => {
            this.catalog = res.content;
            this.form = this.catalog;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
        preview: this.form.preview,
        meta: this.form.meta,
        path: this.form.path,
        locales: this.form.locales,
        groups: this.catalog ? this.catalog.groups : []
      };

      if (!this.catalog)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/planting", data)
            .then((res) => {
              this.$router.replace("/planting/" + res.content.uuid);
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/planting/" + this.catalog.uuid, data)
            .then(() => {
              this.fetch(this.catalog.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },
    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити каталог і пов'язані з ним  дані?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CATALOG_SERVICE + "/manage/planting/" + this.catalog.uuid)
              .then(() => {
                this.$router.replace("/planting");
              });
      })
    },
  }
};
</script>

<style scoped>

</style>
