<template>
  <div>
    <div class="editorx_body">
      <div class id="codex-editor"/>
    </div>
  </div>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Quote from '@editorjs/quote';
import RawTool from '@editorjs/raw';
import SimpleImage from "@editorjs/simple-image";
import AnchorTune from 'editorjs-anchor';
import Delimiter from '@editorjs/delimiter';
import ImageTool from '@editorjs/image';
import Http from "@/lib/Http";

export default {
  name: "ActionEdit",
  props: {
    modelValue: Object,
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue() {
      if (!this.inited) {
        window.editor.render(this.modelValue)
        this.inited = this;
      }

    },
  },
  created() {
    this.create();
  },
  data() {
    return {
      inited: false
    }
  },
  methods: {
    create: function () {
      const _this = this;
      window.editor = new EditorJS({
        data: this.modelValue,
        holder: "codex-editor",
        autofocus: true,
        /**
         * This Tool will be used as default
         */
        defaultBlock: "paragraph",
        tools: {
          header: {
            class: Header,
            shortcut: "CMD+SHIFT+H"
          },
          anchorTune: AnchorTune,
          delimiter: Delimiter,
          simpleImage: SimpleImage,
          image: {
            class: ImageTool,
            config: {
              field: 'file',
              types: 'image/jpeg, image/png, image/webp',
              uploader: {
                uploadByFile(file) {
                  console.log(file);
                  const fd = new FormData();
                  fd.append('file', file, file.name);
                  return Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/image/upload/" + process.env.VUE_APP_PIO_BUCKET_ICON + "/multipart", fd)
                      .then(res => {
                        return {
                          success: 1,
                          file: {
                            url: process.env.VUE_APP_MEDIA_BASE_URL + '/media/' + res.data.path,
                          }
                        };
                      });
                },
              }
            }
          },
          quote: Quote,
          raw: RawTool,
          list: {
            class: List
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: "."
            }
          }
        },
        tunes: ['anchorTune'],
        onReady: function () {
          console.log("ready");
        },
        onChange: function () {
          window.editor.save().then(savedData => {
            _this.$emit('update:modelValue', savedData);
            console.log(savedData);
          });
        }
      });
    },
  }
};
</script>

<style scoped>

.editorx_body {
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.dropdown-menu a {
  cursor: pointer;
  margin-bottom: 4px;
}
</style>
