<template>
  <transition name="fade">
    <div v-if="isVisible" class="confirm-modal">

      <div class="window">

        <div class="body">
          <div class="title">{{ title }}</div>
          <p v-html="message"></p>

          <div class="stage" v-for="stage in stages" :key="stage">
            {{ stage.label }}<span v-bind:class="'status status-'+stage.status">{{ stage.status }}</span>
          </div>
        </div>


        <div class="actions" v-bind:class="cancelButton ? 'two-cols':''">
          <button class="btn-vc btn-cancel btn-left" @click="_cancel" v-if="cancelButton">{{ cancelButton }}</button>
          <button class="btn-vc btn-ok" @click="_confirm" v-if="okButton">{{ okButton }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ConfirmDialogue',

  data: () => ({
    isVisible: false,
    title: null,
    message: null,
    okButton: null,
    cancelButton: 'Go Back',
    resolvePromise: null,
    rejectPromise: null,
    stages: [],
    stageComplete: false
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      this.stages = opts.stages ? opts.stages : []
      if (opts.cancelButton)
        this.cancelButton = opts.cancelButton

      // Once we set our config, we tell the popup modal to open
      this.isVisible = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    updateStage(stage) {
      this.stages = this.stages.map(e => e.name === stage.name ? {
        name: stage.name,
        label: stage.label ? stage.label : e.label,
        status: stage.status,
      } : e);
    },

    _confirm() {

      if (!this.stages.length)
        this.stageComplete = true

      if (this.stageComplete)
        this.isVisible = false;

      this.stageComplete = true;
      this.cancelButton = null;
      this.okButton = "Ok"

      this.resolvePromise(true)
    },

    _cancel() {
      this.isVisible = false;
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped>

/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.confirm-modal {
  background-color: rgba(0, 0, 0, 0.29);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 200;
}

.window {
  background: #fff;
  border-radius: 1rem;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

}

.window .body {
  padding: 1rem;
}

.window .stage {
  background-color: #f5f5f5;
  margin-bottom: 2px;
  padding: 2px 10px;
}

.window .stage .status {
  float: right;
  border: 0;
}

.title {
  padding: 0 1rem;
  width: 100%;
  text-align: center;
  line-height: normal;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 5px;
}

.actions {
  width: 100%;
  display: grid;

  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
}


.actions.two-cols {
  grid-template-columns: 1fr 1fr;
}

.btn-vc {
  border-radius: 0 0 1rem 0;
  color: #4083ff;
  background-color: #fff;
  border: 0;
  font-size: 1rem;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
  font-weight: 700;
  outline: none;
  min-height: 50px;
}

.btn-left {
  border-radius: 0;
  border-right: 1px solid #e0e0e0;
}

</style>