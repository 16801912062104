<template>
  <head-panel>
    <template v-slot:body>
      Налаштування
    </template>
  </head-panel>

  <div class="wrapper edit">

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" @click="tab='main'">Основне</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='contacts'?'active':''" @click="tab='contacts'">Контакти</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='service'?'active':''" @click="tab='service'">Обслуговування</a>
      </li>
    </ul>

    <hr/>


    <form @submit.prevent="submit">

      <div class="tab" v-if="tab==='service'">
        <Alert ref="alert"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Кеш</div>
          <div class="col-lg-8">
            <div>
              <div class="mb-2">Видаляє закешовані дані фронту. Наприкликлад меню, налаштування</div>
              <a type="submit" class="btn btn-sm btn-danger" @click="clearFrontCache">Очистити кеш фронтенду</a>
            </div>
            <div class="mt-4">
              <div class="mb-2">Видаляє закешовані дані бекенду</div>
              <a type="submit" class="btn btn-sm btn-danger" @click="clearBackCache">Очистити кеш бекенду</a>
            </div>
          </div>
        </div>
      </div>

      <div class="tab" v-if="tab==='main'">
        <Alert ref="alert"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Назва сайту</div>
          <div class="col-lg-8">
            <div class="mb-3">
              <input type="text" class="form-control" v-model="form.siteName" placeholder="Title..."
                     :class="{ 'is-invalid' : v$.form.siteName.$error}">
              <div class="invalid-feedback" v-for="error of v$.form.siteName.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
          </div>
        </div>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>
    </form>

    <div class="tab" v-if="tab==='contacts'">
      <contacts-tab />
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import { maxLength} from "@vuelidate/validators";
import ConfirmDialogue from "../../components/ConfirmDialog.vue";
import ContactsTab from "./_contacts.vue";

export default {
  name: "Setting",

  components: {
    HeadPanel, Alert, ConfirmDialogue, ContactsTab
  },
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        siteName: {
          maxLength: maxLength(30),
        }
      }
    };
  },
  data() {
    return {
      tab: 'main',
      settings: null,
      form: {
        siteName: null,
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/setting")
          .then((res) => {
            this.settings = res.content;
            for (const setting of this.settings) {
              if (setting.name === 'site:name')
                this.form.siteName = setting.value;
            }
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      await Http
          .post(process.env.VUE_APP_CRM_SERVICE + "/setting", {
            "name": "site:name",
            "description": "Назва сайту",
            "type": "STRING",
            "value": this.form.siteName,
          });

      this.fetch();
      this.$refs.alert.success('Успішно збережено');
    },

    clearFrontCache: function () {
      this.$refs.confirmDialogue.show({
        title: 'Очищення кешу',
        message: 'Ви дійсно хочете видалити всі кешовані дані?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .post(process.env.VUE_APP_FRONT_SERVICE + "/api/cache/clear", {})
              .then(() => {
                this.$refs.alert.success('Кеш очищено');
              });
      })
    },
    clearBackCache: function () {
      this.$refs.confirmDialogue.show({
        title: 'Очищення кешу',
        message: 'Ви дійсно хочете видалити всі кешовані дані?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/service/cache/clear", {})
              .then(() => {
                this.$refs.alert.success('Кеш очищено');
              });
      })
    }
  }
};
</script>

<style scoped>

</style>
