<template>
  <head-panel>
    <template v-slot:body>
      Меню <span v-if="menu"> {{ menu.name }}</span></template>
  </head-panel>

  <div class="wrapper edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Icon(SVG)</div>
        <div class="col-lg-8">
          <textarea type="text" class="form-control" v-model="form.icon"
                    :class="{ 'is-invalid' : v$.form.icon.$error}" :disabled="parent"/>
          <label for="preview" class="form-label mt-1">Іконка для головного пункту меню</label>
          <div class="invalid-feedback" v-for="error of v$.form.icon.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Превью</div>
        <div class="col-lg-8">

          <div v-if="!form.preview">
            <input class="form-control " type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                   v-on:change="e => uploadPreview(e)" :disabled="!parent">
            <label for="preview" class="form-label mt-1">Файл зоображення категорії. Формат jpg, png. Використовується лише для внутрішніх пунктів меню</label>
          </div>

          <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
               <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                  <path
                      d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                </svg>
          </span>

        </div>
      </div>


      <div class="row ms-2 mb-4" >
        <div class="col-xl-3">Позиція</div>
        <div class="col-lg-8">

          <select class="form-select" v-model="form.position" :class="{ 'is-invalid' : v$.form.position.$error}" :disabled="!parent">
            <option selected value="null"> -- Вибрати --</option>
            <option value="HEAD">Head line</option>
            <option value="LINE1">Line 1</option>
            <option value="LINE2">Line 2</option>
            <option value="LINE3">Line 3</option>
            <option value="LINE4">Line 4</option>
            <option value="LINE5">Line 5</option>
            <option value="FOOTER">Footer</option>
          </select>
          <label for="preview" class="form-label mt-1">Позиція пунту в каталозі меню. Використовується лише для груп меню</label>
          <div class="invalid-feedback" v-for="error of v$.form.position.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Вага</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.weight"
                 :class="{ 'is-invalid' : v$.form.weight.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Активний</div>
        <div class="col-lg-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="activeSwitchCheckDefault" v-model="form.enabled">
            <label class="form-check-label" for="activeSwitchCheckDefault">Відобразити меню на сайті</label>
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Посилання</div>
        <div class="col-lg-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="activeSwitchCheckDefault" v-model="form.active">
            <label class="form-check-label" for="activeSwitchCheckDefault">Використовувати як посилання</label>
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Url</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.url" :class="{ 'is-invalid' : v$.form.url.$error}" :disabled="!form.active">
          <div class="invalid-feedback" v-for="error of v$.form.url.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Переклади</div>
        <div class="col-lg-8">
          <locale-form v-model="form.locales"/>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import {randomString} from "@/lib/Util";
import LocaleForm from "../../components/LocaleForm";
import {useVuelidate} from "@vuelidate/core";
import {helpers, integer, maxLength, required, requiredIf} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, LocaleForm
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  computed: {
    parent() {
      return this.$route.params.parent ?? this.menu?.parent ?? null;
    },
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid name format', (v) => v.match("^[a-z0-9\\-]+$"))
        },
        position: {},
        weight: {
          integer
        },
        url: {
          required: requiredIf(this.form.active),
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid url format', (v) => !this.form.active || v.match("^[a-z0-9\\-.:_/]+$"))
        },
        icon: {
          maxLength: maxLength(5000),
        },
        locales: {
          required
        }
      }
    };
  },
  data() {
    return {
      menu: null,
      form: {
        name: randomString(20).toLowerCase(),
        position: null,
        weight: null,
        enabled: null,
        active: true,
        url: null,
        locales: null,
        icon: null,
        preview: null
      }
    }
  },
  methods: {
    fetch: function (name) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/menu/" + name)
          .then((res) => {
            this.menu = res.content;
            this.form = res.content;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        type: "catalog",
        parent: this.parent,
        name: this.form.name,
        position: this.form.position !== 'null' ? this.form.position : null,
        weight: this.form.weight,
        enabled: this.form.enabled,
        active: this.form.active,
        url: this.form.url,
        locales: this.form.locales,
        icon: this.form.icon,
        preview: this.form.preview
      };

      if (!this.menu)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/menu", data)
            .then(() => {
              this.$router.replace("/menu/" + data.name);
              this.fetch(data.name);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.content);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/menu/" + data.name, data)
            .then(() => {
              this.fetch(data.name);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.content);
            });
    },
    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/image/upload/" + process.env.VUE_APP_PIO_BUCKET_ICON + "/multipart", fd)
          .then(res => {
            this.form.preview = res.data;
          });
    },
    removePreview: function () {
      this.form.preview = null;
    }
  }
};
</script>

<style scoped>

.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}

</style>
