<template>
  <head-panel>
    <template v-slot:body>Теги
      <router-link to="/tag/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="tag"
            v-model="search"
        />
      </div>
      <div class="item ms-3">
        <DropdownFilter
            label="Словники"
            name="vocabulary"
            path="tag"
            :items="this.vocabularies"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Назва</th>
        <th scope="col">Словник</th>
        <th scope="col">Позиція</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="tag in tags" :key="tag">
        <tr class="menu">
          <td>
            {{ tag.id }}
            <svg  v-if="lockedVocabulary.includes(tag.vocabulary)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
            </svg>
          </td>
          <td>{{ $filters.locale(tag.locales) }}</td>
          <td>{{ tag.vocabulary }}</td>
          <td>{{ tag.weight }}</td>
          <td class="actions">
            <router-link :to="'/tag/'+tag.id" v-if="!lockedVocabulary.includes(tag.vocabulary)">Ред.</router-link>
            <a @click="remove(tag)" v-if="!lockedVocabulary.includes(tag.vocabulary)">Видалити</a>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";
import DropdownFilter from "../../components/DropdownFilter";


export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter, DropdownFilter, ConfirmDialogue, Pagination
  },
  created() {
    this.fetchVocabularies();
    this.fetch();
  },
  computed: {
    lockedVocabulary: function () {
      return ['category', 'brand', 'badge'];
    },
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
  },
  data() {
    return {
      search: null,
      tags: [],
      vocabularies: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage" + this.$route.fullPath)
          .then((res) => {
            this.tags = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchVocabularies: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/vocabulary")
          .then((res) => {
            this.vocabularies = res.content.map(vocabulary => {
              return {'value': vocabulary.id, 'label': vocabulary.id};
            });
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити тег?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/" + item.id)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

</style>
