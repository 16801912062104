<template>

  <div class="date-range">
    <VueDatePicker v-model="model" @update:model-value="change" range multi-calendars :enable-time-picker="false"></VueDatePicker>
  </div>

</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment'

export default {
  name: "DateRangeFilter",
  components: {VueDatePicker},
  props: {
    modelValue: Array,
    path: String,
    updateRoute: Boolean,
    defaultRange: Array
  },
  watch: {
    $route() {
      let from = this.$route.query['from'] ?? null;
      let to = this.$route.query['to'] ?? null;

      if (from && to)
        this.model = [from, to]
      else
        this.model = this.defaultRange;
    },
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    change: function () {
      let date = [];

      if (this.model && this.model.length)
        for (let item of this.model)
          if (item != null)
            date.push(moment(item).format("YYYY-MM-DD"));

      this.routePush(date.length ? date : null);
      this.$emit('update:modelValue', date);
      this.$emit('onChange', date);
    },
    routePush: function (val) {

      if (!this.updateRoute)
        return;

      let query = {};

      for (let p of Object.keys(this.$route.query)) {
        if (p === 'page')
          continue;
        query[p] = this.$route.query[p];
      }


      if (val && val.length === 2) {
        query.from = val[0];
        query.to = val[1];
      } else if (val && val.length === 1) {
        query.from = val[0];
        delete query.to;
      } else {
        delete query.from;
        delete query.to;
      }
      this.$router.push({path: this.path, query: query});
    }
  }
}
</script>

<style scoped>
.date-range {
  width: 320px;
}
</style>