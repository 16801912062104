<template>
  <head-panel>
    <template v-slot:body>
      Бренд <span v-if="brand"> {{ brand.name }}</span></template>
  </head-panel>

  <div class="wrapper">

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page" @click="tab='main'">Основне</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="tab='meta'" v-if="brand">Мета</a>
      </li>
    </ul>

    <hr/>

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="tab" v-show="tab==='main'">
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Ідентифікатор</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name" :disabled="!!brand"
                   :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Статус</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="form.status">
              <label class="form-check-label" for="flexSwitchCheckChecked">
                Статус бренду
              </label>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Топ</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="form.top">
              <label class="form-check-label" for="flexSwitchCheckChecked">Дозволяє відобразити бренд на
                головній</label>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Превью</div>
          <div class="col-lg-8">
            <div v-if="!form.preview">
              <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                     v-on:change="e => uploadPreview(e)">
              <label for="preview" class="form-label mt-1">Використовується для топ брендів та пошуку. Формат jpg,
                png</label>
            </div>

            <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Переклади</div>
          <div class="col-lg-8">
            <locale-form v-model="form.locales"/>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>

      <div class="tab" v-show="tab==='meta'">
        <meta-form v-model="form.meta" :with-label="true"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import LocaleForm from "../../components/LocaleForm";
import MetaForm from "../../components/MetaForm";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, LocaleForm, MetaForm
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid name format', (v) => v.match("^[a-z0-9\\-]+$"))
        },
        status: {
          required
        },
      }
    };
  },
  data() {
    return {
      tab: 'main',
      brand: null,
      vocabularies: [],
      form: {
        name: null,
        status: false,
        top: null,
        preview: null,
        locales: null,
        meta: null
      }
    }
  },
  methods: {
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/brand/" + id)
          .then((res) => {
            this.brand = res.content;
            this.form = this.brand;
            this.form.status = this.brand.status === 'ACTIVE';
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLED',
        isTop: this.form.top,
        preview: this.form.preview,
        locales: this.form.locales,
        meta: this.form.meta
      };

      if (!this.brand)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/brand", data)
            .then((res) => {
              this.$router.replace("/brand/" + res.content.id);
              this.fetch(res.content.id);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/brand", data)
            .then(() => {
              this.fetch(this.brand.name);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },
    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/image/upload/" + process.env.VUE_APP_PIO_BUCKET_ICON + "/multipart", fd)
          .then(res => {
            this.form.preview = res.data;
          });
    },
    removePreview: function () {
      this.form.preview = null;
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>
