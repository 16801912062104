<template>
  <div class="alert" v-bind:class="(active ? 'a-show':'a-hide')+' '+cls" role="alert">
    {{ message }}
  </div>
</template>

<script>
// import {ref} from 'vue'

export default {
  name: "Alert",
  expose: ['success', 'danger'],
  data() {
    return {
      active: false,
      cls: 'alert-success',
      message: '',
    }
  },
  methods: {
    success: function (message) {
      this.message = message;
      this.active = true;
      this.cls = 'alert-success';
      setTimeout(() => this.active = false, 12000);
    },
    danger: function (message) {
      this.message = message;
      this.active = true;
      this.cls = 'alert-danger';
      setTimeout(() => this.active = false, 12000);
    }
  }
}
</script>

<style scoped>
.a-show {
  opacity: 1;
  transition: all 0.7s;
}

.a-hide {
  opacity: 0;
  transition: all 2s;
}

.alert-sm{
  padding: 4px 8px !important;
}

</style>