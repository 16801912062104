<template>
  <Alert ref="alert"/>
  <form @submit.prevent="submit">
    <div class="row ms-2 mb-4">
      <div class="col-xl-3">Телефони</div>
      <div class="col-lg-8">

        <div class="row mb-2" v-for="(item, index) in form.phones" :key="item">
          <div class="col-4 col-xl-3">
            <input type="text" class="form-control"
                   v-maska="'+38 (0##) ###-##-##'"
                   placeholder="+38 (0"
                   @click="item.phone='+38 (0'"
                   v-model="item.phone"
                   :class="{ 'is-invalid' : v$.form.phones.$each.$response.$errors[index].phone.length}">
            <div class="invalid-feedback" v-for="error of v$.form.phones.$each.$response.$errors[index].value"
                 :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
          <div class="col-2">
            <input type="text" class="form-control" placeholder="Часи роботи"
                   v-model="item.workhours.default">
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Додайте уточнення до телефону"
                   v-model="item.description.default">
          </div>
        </div>
      </div>
    </div>

    <hr class="mb-4"/>

    <div class="row ms-2 ">
      <div class="col-xl-3">Email</div>
      <div class="col-lg-8">
        <div class="row mb-2">
          <div class="col-4">

            <div class="form-group ">
              <input type="text" class="form-control" placeholder="Email"
                     v-model="form.email"
                     :class="{ 'is-invalid' : v$.form.email.$error}">
              <small class="form-text text-muted">Публічний email для контактів</small>
              <div class="invalid-feedback" v-for="error of v$.form.email.$errors"
                   :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>

    <hr class="mb-4"/>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">Месенджери</div>
      <div class="col-lg-8">


        <div class="row mb-2">
          <div class="col-4">

            <div class="form-group mb-3">
              <input type="text" class="form-control" placeholder="Telegram"
                     v-model="form.messengers.telegram"
                     :class="{ 'is-invalid' : v$.form.messengers.telegram.$error}">
              <small class="form-text text-muted">Посилання на телеграм підтримки. Наприклад:
                https://t.me/livegramsupportbot</small>
              <div class="invalid-feedback" v-for="error of v$.form.messengers.telegram.$errors"
                   :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

            <div class="form-group mb-3">
              <input type="text" class="form-control" placeholder="Viber"
                     v-model="form.messengers.viber"
                     :class="{ 'is-invalid' : v$.form.messengers.viber.$error}">
              <small class="form-text text-muted">Посилання на viber підтримки. Наприклад: </small>
              <div class="invalid-feedback" v-for="error of v$.form.messengers.viber.$errors"
                   :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>


    <div class="row ms-2 mb-4">
      <div class="col-xl-6">
        <button type="submit" class="btn btn-primary">Зберегти</button>
      </div>
    </div>

  </form>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, email} from "@vuelidate/validators";
import Http from "@/lib/Http";
import Alert from "@/components/Alert.vue";
import {maska} from "maska";

export default {
  name: "_contacts",
  components: {
    Alert
  },
  directives: {maska},
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        phones: {
          $each: helpers.forEach({
            phone: {
              maxLength: maxLength(255)
            },
            workhours: {
              maxLength: maxLength(50)
            },
            description: {
              maxLength: maxLength(255)
            }
          })
        },
        email: {email, maxLength: maxLength(255)},
        messengers: {
          telegram: {
            maxLength: maxLength(255)
          },
          viber: {
            maxLength: maxLength(255)
          },
        },
      }
    };
  },
  data() {
    return {
      settings: [],
      form: {
        messengers: {
          telegram: null,
          viber: null
        },
        email: null,
        phones: [
          {
            phone: null,
            workhours: {
              default: null
            },
            description: {
              default: null
            },
          },
          {
            phone: null,
            workhours: {
              default: null
            },
            description: {
              default: null
            },
          },
          {
            phone: null,
            workhours: {
              default: null
            },
            description: {
              default: null
            },
          }
        ],
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/setting")
          .then((res) => {
            this.settings = res.content;
            for (const setting of this.settings) {

              if (setting.name === 'main:phone:list')
                this.form.phones = setting.value.map(item => {
                  return {
                    phone: item.phone ?? null,
                    workhours: item.workhours ?? {},
                    description: item.description ?? {}
                  }
                });

              if (setting.name === 'contact:email')
                this.form.email = setting.value;

              if (setting.name === 'contact:messengers')
                this.form.messengers = {telegram: setting.value.telegram ?? null, viber: setting.value.viber ?? null};

            }

          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;


      if (this.form.phones.length)
        await Http
            .post(process.env.VUE_APP_CRM_SERVICE + "/setting", {
              "name": "main:phone:list",
              "description": "Основні телефони",
              "type": "JSON",
              "value": this.form.phones
            });

      if (this.form.email)
        await Http
            .post(process.env.VUE_APP_CRM_SERVICE + "/setting", {
              "name": "contact:email",
              "description": "Email",
              "type": "STRING",
              "value": this.form.email
            });

      if (this.form.messengers)
        await Http
            .post(process.env.VUE_APP_CRM_SERVICE + "/setting", {
              "name": "contact:messengers",
              "description": "Месенджери",
              "type": "JSON",
              "value": this.form.messengers
            });


      this.$refs.alert.success('Успішно збережено');
    }
  }
}
</script>

<style scoped>

</style>