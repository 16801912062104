<template>
  <head-panel>
    <template v-slot:body>
      Інформація про клієнта <span v-if="client"> {{ client.uuid }}</span></template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="client">

    <tab :client="client" />

    <hr/>

    <div class="row">
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Клієнт</h5>
            <h6 class="card-subtitle mb-2 mt-3 ">{{ client.name }}</h6>
            <p class="card-text">
              Телефон: {{ client.phone }}<br/>
              Email: {{ client.email }}<br/>
              Створений: {{ $filters.date(client.createdAt) }}<br/>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">

        <div class="card mb-4" >
          <div class="card-body">
            <h5 class="card-title">Баланс</h5>
            <p class="card-text" v-if="balance">
              {{ balance.amount / 100 }} ₴
            </p>
            <p class="card-text" v-else>
              Відсутній
            </p>
          </div>
        </div>

      </div>

    </div>


  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Tab from "@/views/client/Tab.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, Tab
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      client: null,
      balance: null,
      orders: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/client/" + this.$route.params.id)
          .then((res) => {
            this.client = res.content;

            if (this.client.balance)
              this.fetchBalance(this.client);

          });
    },
    fetchBalance(client) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/client/" + client.uuid + "/balance")
          .then((res) => {
            this.balance = res.content;
          });
    },
  }
};
</script>

<style scoped>


</style>