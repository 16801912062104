import moment from "moment";

export default function config(app) {


    app.config.globalProperties.$filters = {

        hour(value, format) {

            if (!format)
                format = 'HH';

            return moment(String(value)).format(format);
        },

        minute(value, format) {

            if (!format)
                format = 'mm';

            return moment(String(value)).format(format);
        },

        date(value, format) {

            if (!format)
                format = 'YYYY-MM-DD';

            return moment(String(value)).format(format);
        },

        dateTime(value) {
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },

        price(velue) {
            return velue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        locale(locale) {
            const lang = 'default';
            return locale[lang] || locale[Object.keys(locale)[0]]
        },

        morph(int, array) {
            return (array = array || ['шт', 'шт', 'шт']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
        },

        status(status) {
            const labels = {
                new: 'Новий',
                processing: 'В обробці',
                in_progress: 'В обробці',
                complete: 'Завершений',
                cancel: 'Скасований',
                fail: 'Помилка',
            };
            return labels[status.toLowerCase()];
        },

        image(img, preset, webp = false) {

            const config = {
                marketPioUrl: process.env.VUE_APP_MEDIA_BASE_URL,
                marketPioBucketId: process.env.VUE_APP_PIO_BUCKET_ICON
            }

            if (!img)
                return '/img/no-image-icon.png';

            const path = !webp ? img.path : img.replace(/\.(jpg|jpeg|png)$/, '.webp');
            return config.marketPioUrl + '/media/' + path.replace("origin", preset);
        },


        images(product, preset, webp = false) {
            if (!product.images || !product.images.length)
                return ['/img/no-image-icon.png'];

            return product.images.map(img => {
                return this.image(img, preset, webp);
            });
        }
    }
}
