<template>
  <head-panel>
    <template v-slot:body>Продукти
      <router-link to="/product/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="product"
            v-model="filter.search"
        />
      </div>
      <div class="item ms-3">

        <DropdownFilter
            label="Розділ"
            name="root"
            path="product"
            :multiple="false"
            :items="this.categories"
            v-model="filter.root"
        />

      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">SKU</th>
        <th scope="col">Назва</th>
        <th scope="col">Наявність</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="product in products" :key="product">
        <tr class="menu">
          <td>{{ product.sku }}</td>
          <td>{{ product.name }}</td>
          <td>
            <span v-bind:class="product.price && product.price.available? 'status status-active' : 'status'">
              {{ product.price && product.price.available ? 'В наявності' : 'Відсутній' }}
            </span>
          </td>
          <td class="actions">
            <router-link :to="'/product/'+product.sku">Ред.</router-link>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";
import DropdownFilter from "../../components/DropdownFilter";

export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue, Pagination, DropdownFilter
  },
  created() {
    this.fetchRootCategories();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
    "filter.root"(root) {
      this.holdRoot(root[0]);
    }
  },
  data() {
    return {
      defaultRoot: 'sadovaia-tehnika',
      filter: {
        search: null,
        root: null
      },
      products: [],
      categories: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      this.$route.query.category = this.getRoot();
      const search = new URLSearchParams(this.$route.query);
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/search?" + search.toString())
          .then((res) => {
            this.products = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchRootCategories: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/list?root=true")
          .then((res) => {

            this.defaultRoot = res.content && res.content.length ? res.content[0].name : null;

            this.filter.root = [this.getRoot(res.content)];

            this.categories = res.content.map(root => {
              return {
                value: root.name,
                label: this.$filters.locale(root.locales)
              };
            });
          })
          .then(() => this.fetch());
    },

    holdRoot: function (root) {
      this.$cookies.set("product.default.root", root);
    },

    getRoot: function () {

      if (this.$route.query.category)
        return this.$route.query.category;

      if (this.$cookies.get("product.default.root"))
        return this.$cookies.get("product.default.root");

      return this.defaultRoot;
    },
  }
};
</script>

<style scoped>

</style>
