<template>
  <head-panel>
    <template v-slot:body>Початок роботи</template>
  </head-panel>

  <div class="wrapper">
    <p v-if="!$session.available.length">
      У вас відсутні магазини в управлінні. Дочекайтесь доки вас запросять менеджером в існуючий магазин чи створіть
      свій власний
    </p>

    <table v-else class="table">
      <thead>
      <tr>
        <th>UUID</th>
        <th>Назва</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in $session.available" :key="item">
        <td>{{ item.uuid }}</td>
        <td>{{ item.name }}</td>
        <td class="actions">
          <button class="btn btn-primary btn-sm" @click="use(item)">Вибрати</button>
        </td>
      </tr>
      </tbody>
    </table>

    <!--    <router-link to="/market/add">Створити власний магазин</router-link>-->
  </div>

</template>

<script>
import HeadPanel from "../../components/HeadPanel";

export default {
  name: "Welcome",
  components: {
    HeadPanel
  },
  props: {
    modelValue: Object,
  },
  emits: ['select'],
  created() {
  },
  methods: {
    use: function (val) {
      location.href = '/?muid=' + val.uuid
    },
  }
};
</script>

<style scoped>

.wrapper {
  text-align: center;
}

</style>