<template>
  <div class="head-title">
    <slot name="body">
      Home
    </slot>
    <slot name="top">
      <div class="top-panel">
        <a @click="logout" class="logout">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power"
               viewBox="0 0 16 16">
            <path d="M7.5 1v7h1V1h-1z"/>
            <path
                d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
          </svg>
        </a>
      </div>
    </slot>

  </div>
</template>

<script>

import OAuth from "@/lib/OAuth2";

export default {
  name: "HeadPanel",
  computed: {
    logoutUrl() {
      return process.env.VUE_APP_OAUTH_URL + '/logout?redirect_uri=' + process.env.VUE_APP_OAUTH_REDIRECT_URL;
    }
  },
  methods: {
    logout: function () {
      const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);
      oauth.logout();
    },
  }
}
</script>

<style scoped>

.top-panel {
  float: right;
}

.logout{
  cursor: pointer;
  color: #438de2;
}


</style>