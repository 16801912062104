<template>

  <modal class="short-modal" @close="close">

    <template v-slot:header>
      Short Cut
    </template>
    <template v-slot:body>

      <form @submit.prevent="submit">

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Url</div>
          <div class="col-lg-12">
            <input type="text" class="form-control" v-model="form.url" :disabled="shortCutModel"
                   :class="{ 'is-invalid' : v$.form.url.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.url.$errors"
                 :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Превью</div>
          <div class="col-lg-12">

            <div v-if="!form.preview">
              <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                     v-on:change="e => uploadPreview(e)">
              <label for="preview" class="form-label mt-1">Формат jpg, png</label>
            </div>

            <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>

          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-12">Вага</div>
          <div class="col-lg-12">
            <input type="text" class="form-control" v-model="form.weight"
                   :class="{ 'is-invalid' : v$.form.weight.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Переклади</div>
          <div class="col-lg-12">
            <locale-form v-model="form.locales"/>
          </div>
        </div>


        <div class="row ms-1 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn  btn-primary">Зберегти</button>
          </div>
        </div>

      </form>

    </template>
  </modal>


</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {integer, required} from "@vuelidate/validators";
import Http from "../../lib/Http";
import LocaleForm from "../../components/LocaleForm.vue";
import Modal from "../../components/Modal.vue";

export default {
  name: "ShortCut",
  components: {
    LocaleForm, Modal
  },
  props: {
    modelValue: Object
  },
  created() {
    if (this.modelValue) {
      this.form = this.modelValue;
      this.shortCutModel = this.modelValue;
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        url: {required},
        locales: {required},
        weight: {integer}
      }
    };
  },
  data() {
    return {
      shortCutModel: null,
      form: {
        url: null,
        preview: null,
        locales: null
      }
    }
  },
  methods: {

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      this.$emit('update:modelValue', this.form);
    },

    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/image/upload/" + process.env.VUE_APP_PIO_BUCKET_ICON + "/multipart", fd)
          .then(res => {
            this.form.preview = res.data;
          });
    },

    removePreview: function () {
      this.form.preview = null;
    },

    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>
