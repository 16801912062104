<template>
  <head-panel>
    <template v-slot:body>Статистика
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="filter">

      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="uses"
        />
      </div>

    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Код</th>
        <th scope="col">Дисконт</th>
        <th scope="col">Тип</th>
        <th scope="col">Клієнт</th>
        <th scope="col">Дата активації</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.code.code }}</td>
        <td>{{ item.code.discount }}</td>
        <td>{{ item.code.type }}</td>
        <td>
          <router-link v-if="this.$session.can('owner', 'client_read', 'client_manage')"
                       :to="'/client/'+item.client.uuid">
            Деталі
          </router-link>
        </td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">

        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import Pagination from "../../../components/Pagination.vue";
import Tab from "../Tab.vue";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
  name: "PromoCodes",
  components: {
    HeadPanel, Pagination, Tab, SearchFilter
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {

      const query = {};

      if (this.$route.query.search)
        query.search = this.$route.query.search;

      if (this.$route.query.client)
        query.client = this.$route.query.client;

      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/code/usage/search?" + new URLSearchParams(query).toString())
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    }
  }
};
</script>

<style scoped>

</style>