<template>
  <head-panel>
    <template v-slot:body>Клієнти</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="client"
        />
      </div>
      <div class="item">

      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Імя</th>
        <th scope="col">Телефон</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.name }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/client/'+item.uuid">Деталі</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
        Http
            .get(process.env.VUE_APP_CRM_SERVICE + this.$route.fullPath)
            .then((res) => {
              this.items = res.content;
              this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
              this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
            });
    }
  }
};
</script>

<style scoped>


</style>