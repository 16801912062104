<template>

  <modal class="short-modal" @close="close">

    <template v-slot:header>
      Фільтр
    </template>
    <template v-slot:body>

      <form @submit.prevent="submit">


        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Словник</div>
          <div class="col-lg-12">

            <multiselect
                v-model="form.vocabulary"
                :showLabels="false"
                :disabled="disableEditVocabulary"
                :options="vocabularies"
                :multiple="false"
                :taggable="false"
                placeholder="--вибрати--"
                :class="{ 'is-invalid' : v$.form.vocabulary.$error}">
            </multiselect>

            <div class="invalid-feedback" v-for="error of v$.form.vocabulary.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>

          </div>
        </div>


        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Вага</div>
          <div class="col-lg-12">
            <input type="text" class="form-control" v-model="form.weight"
                   :class="{ 'is-invalid' : v$.form.weight.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Пошук</div>
          <div class="col-lg-12">
            <select class="form-select" v-model="form.search" >
              <option v-for="item in searches" :key="item" v-bind:value="item">{{ item }}</option>
            </select>

          </div>
        </div>


        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Розгорнутий</div>
          <div class="col-lg-12">
            <select class="form-select" v-model="form.expand" >
              <option v-for="item in expands" :key="item" v-bind:value="item">{{ item }}</option>
            </select>

          </div>
        </div>

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Заголовок</div>
          <div class="col-lg-12 ">

            <div class="form-check form-switch mt-1">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckTitleUse" v-model="form.titleUse">
              <label class="form-check-label" for="flexSwitchCheckTitleUse">Формування заголовку каталогу на основі
                даного фільтру</label>
            </div>

          </div>
        </div>

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Переклади</div>
          <div class="col-lg-12">
            <locale-form v-model="form.locales"/>
          </div>
        </div>


        <div class="row ms-1 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn  btn-primary">Зберегти</button>
          </div>
        </div>

      </form>

    </template>
  </modal>


</template>

<script>
import
{useVuelidate} from "@vuelidate/core";
import {integer, maxValue, required} from "@vuelidate/validators";
import LocaleForm from "../../components/LocaleForm.vue";
import Modal from "../../components/Modal.vue";
import Http from "@/lib/Http";
import Multiselect from "vue-multiselect";

export default {
  name: "ShortCut",
  components: {
    Multiselect,
    LocaleForm, Modal
  },
  props: {
    category: Object,
    modelValue: Object
  },
  created() {
    this.fetchVocabularies();

    if (this.modelValue && this.modelValue.vocabulary)
      this.disableEditVocabulary = true;

    if (this.modelValue)
      this.form = this.modelValue;
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        vocabulary: {required},
        locales: {required},
        weight: {integer, maxValue: maxValue(10000)},
        titleUse: {}
      }
    };
  },
  computed: {
    expands() {
      return ['AUTO', 'EXPANDED', 'COLLAPSED'];
    },
    searches() {
      return ['AUTO', 'ENABLED', 'DISABLED'];
    },
  },
  data() {
    return {
      disableEditVocabulary: false,
      vocabularies: [],
      form: {
        vocabulary: null,
        preview: null,
        locales: null,
        titleUse: false,
        expand: null,
        search: null,
      }
    }
  },
  methods: {
    fetchVocabularies: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/vocabulary")
          .then(res => {
            const exclude = this.category.filters && !this.disableEditVocabulary ? this.category.filters.map(el => el.vocabulary) : [];
            this.vocabularies = res.content
                .filter(el => !exclude.includes(el.id))
                .map(el => el.id)
                .sort((a, b) => a.localeCompare(b));
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      this.$emit('update:modelValue', this.form);
    },

    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style>
.multiselect .multiselect__content-wrapper{
  max-height: 260px !important;
}
</style>
